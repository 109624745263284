
import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./post.module.scss";

export default (props) => {

	const {
		data: {
			postInfo,
			postInfo: {
				title,
				date,
				ACFPostInfo: {
					postInfo: {
						left: {
							blurb,
							requestAppointmentLink,
							insurancesHeading
						},
						right: {
							postContent: content,
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			insurance: {
				nodes: insurances
			},
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
			appointmentPage,
			blogPages: {
				nodes: blogPages
			}
		}
	} = props;

	return (
		<Layout hideCtaButton={true}>
			<Seo post={postInfo} />
			<div className={`${styles.top} hero`}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styles.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styles.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styles.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styles.bgxl} loading={"eager"} /> }
					{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
					<WPLink link={{target: '', title: 'Make an Appointment', url: '/schedule-online/'}} passedClass={styles.mobileapptlink} passedIconClass={styles.icon} />


				<div className={styles.left}>
					{ blogPages && blogPages.length > 0 && <Link to={blogPages[0].link} className={styles.moreblog}>Find more posts</Link> }
					{ title && <h1 className={styles.heading} dangerouslySetInnerHTML={{__html: title}}></h1> }
					{ date && <span className={styles.date}>{date}</span> }
				</div>

				<div className={styles.right}>
					<div className={styles.lefttop}>
						{ blogPages && blogPages.length > 0 && <p className={styles.patientname}>{blogPages[0].title}</p> }
					</div>
					<div className={styles.leftbottom}>
						{ blurb && <div className={styles.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLink && <WPLink link={requestAppointmentLink} passedClass={styles.request} /> }
						{ !requestAppointmentLink && appointmentPage && appointmentPage.nodes.length > 0 && <Link to={appointmentPage.nodes[0].link} className={styles.request}>Request appointment</Link> }
					</div>
				</div>
			</div>
			<div className={styles.bottom}>
				
				<div className={styles.left}>
					<div className={styles.patientcontent}>
						<div className={styles.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }
						</div>
					</div>
				</div>

				<div className={styles.right}>
					<div className={styles.insurance}>
						<span className={styles.heading} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">{ insurancesHeading ? insurancesHeading : 'We accept the following insurance.' }</span>
						<div className={styles.insurancebottom} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							{ insurances && insurances.map( (insurance, index) => {
								const image = insurance.ACFInsurance.logo;
								return image ? <FluidImage key={`insuranceimg-${index}`} image={image} passedClass={styles.insuranceimage} /> : null;
							})}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		postInfo: wpPost(id: { eq: $id }) {
			id
			title
			date(formatString: "MMMM D, YYYY")
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			ACFPostInfo {
				postInfo {
					left {
						blurb
						requestAppointmentLink {
							title
							url
							target
						}
						insurancesHeading
					}
					right {
						postContent
					}
				}
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
			childImageSharp {
			  fixed(width: 10) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		appointmentPage: allWpPage(filter: {template: {templateName: {eq: "Appointments Page"}}}) {
			nodes {
			  link
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		introBGMobile: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 699) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGTablet: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 550) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGDesktop: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 840) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		introBGXL: file(name: {eq: "leap-kids-dental-hero"}) {
			childImageSharp {
			  fluid(maxWidth: 1200) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		calloutBG: file(name: {eq: "callout_overlay_girl"}) {
			childImageSharp {
			  fluid(maxWidth: 295) {
				...GatsbyImageSharpFluid_withWebp
			  }
			}
		}
		insurance: allWpInsurance (sort: {fields: title, order: ASC}) {
		    nodes {
				title
		      	ACFInsurance {
		        	logo {
		        		altText
						localFile {
							childImageSharp {
								fluid(maxWidth: 420){
									...GatsbyImageSharpFluid_withWebp
								}
							}
							publicURL
                			extension
						}
		        	}
		      	}
		    }
		}
		blogPages: allWpPage(filter: {template: {templateName: {eq: "Blog Page"}}, status: {eq: "publish"}}) {
			nodes {
				id
				link
				title
			}
		}
	}
`;